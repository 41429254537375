import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../state/appContext";
import "../components/loginRegister/login_register.css";
export const PasswordReset = () => {
  const { actions } = useContext(Context);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      const fetchData = async () => {
        const resp = await actions.checkToken();
        setUser((prev) => (prev = resp.user));
      };
      fetchData();
    } else {
      //alert("Link expiró, intentelo de nuevo");
      //navigate('/')
    }
  }, [token, actions]);
  useEffect(()=>{
    if (error!=='') setTimeout(()=>{
        setError(prev=>prev='')
    },3000)
  },[error])
  const handleClick = async () => {
    //pasamos a la actions.updatePassword el password y el token
    if (password !== passwordRepeat) return setError(prev=> prev="Password should match");
    if (password.length < 7) {
        setError(prev=> prev= 'Password must be at least 8 characters');
      }
    const resp = await actions.updatePassword(password, token);
    if (resp.success) {
      setSuccess(prev=> prev=true);
      setTimeout(navigate("/"), 1000);
    } else {
      setSuccess(prev=> prev=false);
    }
  };

  return (
    <section className="container-lg vh-100 d-flex flex-column place-content-center">
      <figure>
        <img
          className="img-fluid"
          height={"400px"}
          width={"400px"}
          src="https://res.cloudinary.com/dchrsbres/image/upload/v1726135665/seiglie/brand/webp/logo192_ecpctt-removebg-preview_r9pwsp_lcqapr.webp"
          alt="seiglie logo"
        />
      </figure>
      <h2 className="fs-1 mb-5">Restablecer contraseña</h2>
      <p className="fs-4 mt-5">
        Hola {user && user.email}, vamos a restablecer la contraseña
      </p>
      <div className="login form-piece bg-primary switched mb-5">
        <form className="login-form form w-sm-100 w-lg-75 mb-5 mx-auto">
          <div className="form-group   mx-auto mb-5">
            <label className="text-white my-5" htmlFor="password">
              password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              required
              className="mx-auto"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group   mx-auto mb-5">
            <label className="text-white my-5" htmlFor="passwordRepeat">
              passwordRepeat
            </label>
            <input
              type="password"
              name="passwordRepeat"
              id="passwordRepeat"
              required
              className="mx-auto"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </div>
          <div className="my-3">
            <input
              type="submit"
              className="btn btn-primary my-5"
              onClick={handleClick}
              value={"send"}
            />
          </div>
        </form>

        {success !== "" ? (
          success ? (
            <div className="container bg-success">
              {" "}
              se ha actualizado la contraseña exitosamente
            </div>
          ) : (
            <div className="container bg-danger"> hubo un problema</div>
          )
        ) : (
          ""
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </div>
    </section>
  );
};
