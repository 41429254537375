import React from 'react'
import { ContactForm } from '../components/contactForm/contact_form'

export const Contact = () => {
  return (
    <section>
      <ContactForm/>
    </section>
  )
}
