import { getArticle, getLatestArticles } from "./articleStories";
import {
  getUser,
  uploadImage,
  userLoginRegister,
  userLogout,
} from "./useStories";
import { addError, clearError } from "./errors";

const getState = ({ getStore, getActions, setStore }) => {
  return {
    store: {
      test: "ok",
      auth: false,
      errors: [],
      articles: [
        {
          id: 1,
          description:
            "lorem ipsum, dummy text, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss dddddddddddddddddddddddddddddddddddddddddd fffffffffffffffffffffffffffff la la la la la la olalalala ollalakj hjagsdkjhaskjdajks asjkd kj",
          repository: "react-markdown-reader",
          title: "react-markdown-reader",
          published: "2023-08-12",
          rating: 4.8,
          video: null,
          video_thumbnail: null,
          img: "https://picsum.photos/1200",
        },
        {
          id: 2,
          description:
            "lorem ipsum, dummy text, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss dddddddddddddddddddddddddddddddddddddddddd fffffffffffffffffffffffffffff la la la la la la olalalala ollalakj hjagsdkjhaskjdajks asjkd kj",
          repository: "Repo Two",
          title: "Advanced Node.js",
          published: "2023-09-10",
          rating: 4.6,
          video: "https://example.com/video/node-advanced",
          video_thumbnail:
            "https://example.com/video-thumbnail/node-advanced.jpg",
          img: "https://picsum.photos/1200",
        },
        {
          id: 3,
          description:
            "lorem ipsum, dummy text, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss dddddddddddddddddddddddddddddddddddddddddd fffffffffffffffffffffffffffff la la la la la la olalalala ollalakj hjagsdkjhaskjdajks asjkd kj",
          repository: "Repo Three",
          title: "Full Stack Development",
          published: "2023-07-25",
          rating: 2.9,
          video: "https://example.com/video/fullstack",
          video_thumbnail: "https://example.com/video-thumbnail/fullstack.jpg",
          img: "https://picsum.photos/1200",
        },
        {
          id: 4,
          description:
            "lorem ipsum, dummy text, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss dddddddddddddddddddddddddddddddddddddddddd fffffffffffffffffffffffffffff la la la la la la olalalala ollalakj hjagsdkjhaskjdajks asjkd kj",
          repository: "Repo One",
          title: "Introduction to React",
          published: "2023-08-12",
          rating: 0,
          video: null,
          video_thumbnail: null,
          img: "https://picsum.photos/1200",
        },
        {
          id: 5,
          description:
            "lorem ipsum, dummy text, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss dddddddddddddddddddddddddddddddddddddddddd fffffffffffffffffffffffffffff la la la la la la olalalala ollalakj hjagsdkjhaskjdajks asjkd kj",
          repository: "Repo Two",
          title: "Advanced Node.js",
          published: "2023-09-10",
          rating: 4.6,
          video: "https://example.com/video/node-advanced",
          video_thumbnail:
            "https://example.com/video-thumbnail/node-advanced.jpg",
          img: "https://picsum.photos/1200",
        },
        {
          id: 6,
          description:
            "lorem ipsum, dummy text, aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss dddddddddddddddddddddddddddddddddddddddddd fffffffffffffffffffffffffffff la la la la la la olalalala ollalakj hjagsdkjhaskjdajks asjkd kj",
          repository: "Repo Three",
          title: "Full Stack Development",
          published: "2023-07-25",
          rating: 2.9,
          video: "https://example.com/video/fullstack",
          video_thumbnail: "https://example.com/video-thumbnail/fullstack.jpg",
          img: "https://picsum.photos/1200",
        },
      ],
    },
    actions: {
      test: () =>
        fetch("/api/service/test")
          .then((resp) => resp.json())
          .then((data) => console.log(data)),
      logout: () => userLogout(setStore),
      loginRegister: async (endpoint, credentials) => {
        try {
          const res = await userLoginRegister(setStore, endpoint, credentials);
          if (!res.success) throw new Error("Something went wrong");
          setStore({ user: res.user, auth: res.auth });
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      addError: (error) => setStore({errors: [...getStore().errors, addError(error)]}),
      clearError: () => setStore({ errors: clearError() }),
      getUser: async () => {
        try {
          const resp = await getUser();
          console.log(resp);

          if (!resp.success) throw new Error("Something went wrong");
          setStore({ user: resp.user, auth: resp.auth });
        } catch (error) {
          console.error(error);
        }
      },
      uploadAvatar: async (img) => {
        try {
          if (!img) throw new Error("Avatar missing");
          const res = await uploadImage(img);
          console.log(res);
          if (!res.success) throw new Error("Something went wrong");
          setStore({ user: res.user });
          return { success: true };
        } catch (error) {
          console.error(error);
        }
      },
      getLatest: async () => {
        try {
          const resp = await getLatestArticles("/articles/latest");
          if (!resp.success) throw new Error();
          setStore({ articles: resp.articles });
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
      getArticle: async (title) => {
        try {
          const resp = await getArticle("/articles/latest/" + title);
          if (!resp.success) throw new Error();
          setStore({ article: resp.article });
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      },
      selectArticle: (selected) => {
        setStore({
          article: getStore().articles.filter((el) => el.id === selected)[0],
        });
        getActions().getArticle(getStore().article.repository);
      },
    },
  };
};

export default getState;
