import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./views/home.jsx";
import { Contact } from "./views/contact.jsx";
import { Navbar } from "./components/navbar.jsx";
import { Footer } from "./components/footer.jsx";
import ScrollToTop from "./components/scrollToTop/scrollToTop.jsx";
import { NotFound } from "./views/not_found.jsx";
import injectContext, { Context } from "./state/appContext.jsx";
import { useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { PoliticaCookies } from "./views/politicaCookies.jsx";
import { AvisoLegal } from "./views/aviso_legal.jsx";
import { LoginRegister } from "./views/login_register.jsx";
import { Profile } from "./views/profile.jsx";
import { Dashboard } from "./views/dashboard.jsx";
import { Collection } from "./views/collection.jsx";
import { Articles } from "./views/articles.jsx";
import { PasswordReset } from "./views/password_reset.jsx";
import { ForgotPassword } from "./views/forgot_password.jsx";

function App() {
  const basename = process.env.REACT_APP_BASENAME || "/";
  // const { store } = useContext(Context);
  // console.log(store)

  //google tag manager
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-PW8FQ2B6",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="App">
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />

            <Route path="/contact" element={<Contact />} />
            <Route path="/cookie_policy" element={<PoliticaCookies />} />
            <Route path="/aviso_legal" element={<AvisoLegal />} />
            <Route path="/auth" element={<LoginRegister />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/articles/:title" element={<Articles />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/resetpassword" element={<PasswordReset />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            

            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default injectContext(App);
