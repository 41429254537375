import React, { useContext } from "react";
import { Context } from "../state/appContext";
import { AuthHelper } from "../helpers/auth.helper";
import { ProfileComponent } from "../components/profile/profile.component";

export const Profile = () => {
  const { store} = useContext(Context);

  return (
    <section className="container">
      <AuthHelper />
      <h3>Welcome {store.user?.email}!</h3>
      <ProfileComponent />
    </section>
  );
};
