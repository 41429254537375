import React from "react";
import './modal.css';
export const Modal = ({ error, title, msg, selfClose, open, close }) => {



  return (
    open && (
      <div className="notification_modal">
        <div className="bg-dark">
          <div className="d-flex justify-content-between align-items-center bg-secondary">
            <h3 className="ms-2 text-black">{title}</h3>
            <span className="close pointer bg-danger p-2" onClick={close}>
              &times;
            </span>
          </div>
          <div className="p-3">

          <h4 className="my-2">{error}</h4>
          <p>{msg}</p>
          <button className=" mt-3 btn btn-primary border" onClick={close}>
            OK
          </button>
          </div>
        </div>
      </div>
    )
  );
};
