import React, { useEffect, useState } from "react";

export const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [error, setError] = useState("");
  const [laoding, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return setError((prev) => (prev = "email requerido"));
    if (email.trim().length < 4)
      return setError((prev) => (prev = "email no valido"));
    setLoading((prev) => (prev = true));
    //fetch
    //if ok
    setLoading(false);
    //else
    setLoading(false);
    //setError((prev) => (prev = respError));
  };

  useEffect(() => {
    if (error !== "")
      setTimeout(() => {
        setError((prev) => (prev = ""));
      }, 3000);
  }, [error]);

  return (
    <section className="container-lg vh-100 d-flex flex-column place-content-center">
      <figure>
        <img
          className="img-fluid"
          height={"400px"}
          width={"400px"}
          src="https://res.cloudinary.com/dchrsbres/image/upload/v1726135665/seiglie/brand/webp/logo192_ecpctt-removebg-preview_r9pwsp_lcqapr.webp"
          alt="seiglie logo"
        />
      </figure>
      <h2 className="fs-1 mb-5">Restablecer contraseña</h2>
      <p className="fs-4 mt-5">
        Inserte correo asociado a la cuenta para recuperar la contraseña.
      </p>
      <div className="login form-piece bg-primary switched mb-5">
        <form
          className="login-form form w-sm-100 w-lg-75 mb-5 mx-auto"
          onSubmit={handleSubmit}
        >
          <div className="form-group   mx-auto mb-5">
            <label className="text-white my-5" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              required
              className="mx-auto"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="my-3">
            <input
              type="submit"
              className="btn btn-primary my-5"
              value={"send"}
            />
          </div>
        </form>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </div>
    </section>
  );
};
