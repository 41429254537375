import { fetcher } from "../helpers/fetcher"; //(endpoint, method, authorization, body)

export const getLatestArticles = async (endpoint) => {
  try {
    const { data, error } = await fetcher(endpoint, "GET", null, null);
    if (error) throw new Error(error);
    return { success: true, data: data.articles };
  } catch (error) {
    console.log("Error ---> ", error.message);
    return { success: false, error: error.message };
  }
};

export const getArticle = async (endpoint) => {
  try {
    const { data, error } = await fetcher(endpoint, "GET", null, null);
    if (error) throw new Error(error);
    return { success: true, data: data.article };
  } catch (error) {
    console.log("Error ---> ", error.message);
    return { success: false, error: error.message };
  }
};

// export const user = (set) => {
//     try {
//         return {success: true}
//     } catch (error) {
//         console.log('Error ---> ',error.message)
//         return {success: false, error: error.message}
//     }
// }
