export const SignupForm = ({ data, handleInputChange, handleSubmit, handleSwitch, errors, isLogin }) => (
    <div className={`signup form-piece bg-primary ${!isLogin ? 'switched' : ''}`}>
      <form className="signup-form " onSubmit={handleSubmit}>
      
        <div className="form-group">
          <label className="text-white" htmlFor="email">Email</label>
          <input type="email" name="email" id="email" className="email" value={data.email} onChange={handleInputChange} />
        </div>
       
        <div className="form-group">
          <label className="text-white" htmlFor="password">Password</label>
          <input type="password" name="password" id="password"  className="pass" value={data.password} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label className="text-white" htmlFor="passwordCon">Confirm Password</label>
          <input type="password" name="passwordCon" id="passwordCon" className="passConfirm" value={data.passwordCon} onChange={handleInputChange} />
          {errors.passConfirmError && <span className="error">{errors.passConfirmError}</span>}
        </div>
        <div className="CTA d-flex justify-content-center">
          <input className="btn btn-primary border" type="submit" value="Signup"  />
          <button className="btn  btn-secondary border-light switch text-white" onClick={handleSwitch}>
            Login
          </button>
        </div>
      </form>
    </div>
  );