import React, { useContext } from "react";
import { StarRating } from "../../starRating/starRating";
import { useNavigate } from "react-router-dom";
import { Context } from "../../state/appContext";
// import ReactPlayer from 'react-player/lazy'

export const ArticlesCard = ({
  id,
  repository,
  title,
  rating,
  video,
  video_thumbnail,
  img,
  createdAt,
  description,
  level,
}) => {
  const navigate = useNavigate();
  const { actions } = useContext(Context);
  const handleClick = (e) => {
    e.stopPropagation();
    actions.selectArticle(id);
    navigate("/articles/" + title);
  };
  return (
    <article className="container p-2 border rounded bg-alpha">
      <div className="d-flex">
        <figure className="w-25">
          <img
            className="img-fluid"
            src={`${video ? video_thumbnail : img}`}
            alt={title}
          />
        </figure>
        <div className="w-75 text-start mx-3 p-3 ">
          <div className="float-end">
            {rating !== 0 ? (
              <StarRating value={rating} />
            ) : (
              <span>Not rated yet</span>
            )}
            <span className="badge bg-secondary my-2 float-end">
              level{level}
            </span>
          </div>
          <div>
            <h3 className="fs-4">{title}</h3>
            <p className="my-3">Published: {createdAt}</p>

            <p className="clamp">
              {description}
            </p>
          </div>
      <div className="container text-white ">
        <span className="badge bg-transparent border rounded">tag</span>
        <span className="badge bg-transparent border rounded">here</span>
      </div>
        </div>
      </div>

    </article>

    // <article className="card p-0 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 text-black ">
    //   <figure
    //     className="card-img-top articles-card"
    //     style={{ backgroundImage: `url(${video ? video_thumbnail : img})` }}
    //   >
    //     <div className="bg-alpha p-3 h-300">
    //       <div className="d-flex ">
    //         {rating !== 0 ? (
    //           <StarRating value={rating} />
    //         ) : (
    //           <span>Not rated yet</span>
    //         )}
    //       </div>
    //       <figcaption className="articles-title pointer" onClick={handleClick}>
    //         {title}
    //       </figcaption>
    //       <a
    //         rel="noreferrer"
    //         target="_blank"
    //         className="nav-link pb-3 github-link"
    //         href={"https://www.github.com/jseiglie/" + repository}
    //         onClick={(e) => e.stopPropagation()}
    //       >
    //         <span className="fa-brands fa-github fa-2xl"></span>
    //       </a>
    //     </div>
    //   </figure>
    // </article>
  );
};
