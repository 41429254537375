import React from "react";

export const Searchbar = () => {
  return (
    <form className="d-flex w-75 mx-auto my-3" role="search">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control bg-transparent text-white"
          placeholder="Search..."
          aria-label="Example text with button addon"
          aria-describedby="button-search"
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          id="button-search"
        >
          <span className="fa-solid fa-magnifying-glass"></span>
        </button>
      </div>
    </form>
  );
};
