import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../state/appContext";
import { MarkdownReader } from "../components/markdownReader/markdownReader";

export const Articles = () => {
  const { title } = useParams();
  const { store, actions } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading((prev) => (prev = true));
      const resp = await actions.getArticle(title);
      if (!resp) return setError((prev) => (prev = true));
      return setLoading((prev) => (prev = false));
    };
    if (!store.article?.repository) {
      load();
    }
  }, [title, actions, store]);

  return (
    <section className="container">
      {loading ? (
        <span>Loading</span>
      ) : (
        !error &&
        store.article && (
          <MarkdownReader user={"jseiglie"} repo={store.article?.repository} />
        )
      )}
      {error && (
        <div className="bg-danger p-3">
          <p>{error}</p>
        </div>
      )}
    </section>
  );
};
