import React from "react";

export const StarRating = (value) => {
  const stars = ["", "", "", "", ""];

  return (
    
    <div className="ms-auto me-2">
      
        
      {stars.map((_, i) =>
        Math.round(value.value >= i + 1) ? (
          <span key={i} className="fa-solid fa-star star-filled  fa-lg"></span>
        ) : (
          ''
        )
      )}
    </div>
  );
};
