import React, { useContext, useEffect, useState } from "react";
import { LoginForm } from "./SigninForm";
import { SignupForm } from "./signupForm";
import { BrandSection } from "./brandSection";
import "./login_register.css";
import { Context } from "../../state/appContext";
import { useNavigate } from "react-router-dom";
import { Modal } from "../modal/modal";
import useModal from "../../hooks/useModal/useModal";

export const LoginRegisterComponent = () => {
  const { store, actions } = useContext(Context);
  const navigate = useNavigate();
  const [endpoint, setEndpoint] = useState("/login");
  const [isLogin, setIsLogin] = useState(true);
  const [keepMeLogged, setKeepMeLogged] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    passwordCon: "",
  });
  const [errors, setErrors] = useState([]);
  const { show, openModal, closeModal } = useModal();

  useEffect(() => {
    store.auth && navigate("/");
  }, [store.auth, navigate]);

  useEffect(() => {
    if (store.errors.length > 0) openModal();
  }, [store.errors, openModal]);

  const handleSwitch = (e) => {
    e.preventDefault(); // Prevent submission when toggling
    setIsLogin((prev) => (prev = !prev));
    endpoint === "/login"
      ? setEndpoint((prev) => (prev = "/register"))
      : setEndpoint((prev) => (prev = "/login"));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => (prev = { ...prev, [name]: value }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$/;
    if (!isLogin) {
      if (formData.password !== formData.passwordCon) {
        newErrors.type = "Passwords missmatch";
        newErrors.msg = "Passwords do not match";
        actions.addError(newErrors);
        valid = false;
      }
    }

    if (!passwordRegex.test(formData.password)) {
      newErrors.type = "Passwords strength error";
      newErrors.msg =
        "Password must be at least 8 characters long with at least one lower case, upper case, number and special character";
      actions.addError(newErrors);
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setEndpoint(isLogin ? "/login" : "/register");
      formData.keepMeLogged = keepMeLogged;
      actions.loginRegister(endpoint, formData);
      // Add success animation logic here
    }
  };

  return (
    <div className="container">
       <Modal error={store.errors[0]?.type} title={'Error'} msg={store.errors[0]?.msg} selfClose={true} open={show} close={closeModal}/>
      <section id="formHolder">
        <div className="login_register-wrapper">
          <div className="container mx-auto">
            <BrandSection isLogin={isLogin} />
          </div>
          <div className="container form d-flex">
            {isLogin ? (
              <div className="justify-content-end">
                <LoginForm
                  keepMeLogged={keepMeLogged}
                  setKeepMeLogged={setKeepMeLogged}
                  data={formData}
                  handleInputChange={handleInputChange}
                  handleSubmit={handleSubmit}
                  handleSwitch={handleSwitch}
                  isLogin={isLogin}
                  errors={errors}
                />
              </div>
            ) : (
              <SignupForm
                data={formData}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                handleSwitch={handleSwitch}
                isLogin={isLogin}
                errors={errors}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
