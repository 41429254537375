import React, { useContext } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import he from "he";
import useReadmeFetcher from "../../hooks/useReadmeFetcher";
import { Context } from "../../state/appContext";
import { Bmac } from "../buymeacoffee/bmac";

export const MarkdownReader = ({ user, repo }) => {
  const { readmeContent, error } = useReadmeFetcher(user, repo);
  const { store } = useContext(Context);
  return (
    <section className="text-start">
      {readmeContent ? (
        <article className="container">
          <div className="d-flex align-items-center justify-content-around border rounded py-4">
            <h1 className="fs-1 ">{store.article?.title}</h1>
            <span className="fa-regular fa-bookmark fa-2xl pointer"></span>
          </div>
          <Bmac />
          <figure>
            <img
              style={{ maxHeight: "30vh" }}
              className="img-fluid object-fit-cover"
              src={store.article?.img}
              alt={store.article?.title}
            />
          </figure>
          <Markdown
            children={he.decode(readmeContent)}
            remarkPlugins={[remarkGfm]}
          />
        </article>
      ) : (
        <p>Cargando contenido...</p>
      )}
      {error && (
        <div className="bg-danger p-3">
          <p>{error}</p>
        </div>
      )}
    </section>
  );
};
