import React from "react";
import { StarRating } from "../../starRating/starRating";
import { useNavigate } from "react-router-dom";
// import ReactPlayer from 'react-player/lazy'

export const LatestCard = ({
  repository,
  title,
  rating,
  video,
  video_thumbnail,
  img,
}) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.stopPropagation();
    navigate("/articles/" + title);
  };
  return (
    <article className="card my-3">
      <figure
        className="card-img-top latest-card"
        style={{ backgroundImage: `url(${video ? video_thumbnail : img})` }}
      >
        <div className="bg-alpha p-3">
          <figcaption className="latest-title pointer" onClick={handleClick}>
            {title}
          </figcaption>
          <a
            className=" nav-link pb-3"
            href={"https://www.github.com/jseiglie/" + repository}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="fa-brands fa-github fa-2xl"></span>
          </a>
          <div className="container text-white">
            <span className="badge bg-transparent border rounded">tag</span>
            <span className="badge bg-transparent border rounded">here</span>
          </div>
        </div>
      </figure>
    </article>
  );
};
