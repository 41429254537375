import React from "react";
import "./bmac.css";

export const Bmac = () => (
  <div className="py-2 d-flex d-flex justify-content-end text-center">
    <p className="mt-1 mb-3">Gracias por apoyar el sitio</p>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.buymeacoffee.com/seiglie"
    >
      <div className="bmac-btn ms-3" role="button"></div>
    </a>
  </div>
);
