import { useState, useEffect, useContext } from "react";
import { Context } from "../../state/appContext";

const useModal = (
  initialOpen = false,
  selfClose = true,
  selfCloseDuration = 5000
) => {
  const { actions } = useContext(Context);
  const [show, setShow] = useState(initialOpen);
  const openModal = () => setShow((prev) => (prev = true));
  const closeModal = () => {
    setShow((prev) => (prev = false));
    actions.clearError();
  };

  useEffect(() => {
    if (selfClose && show) {
      const timer = setTimeout(() => {
        setShow((prev) => (prev = false));
        actions.clearError();
      }, selfCloseDuration);
      return () => clearTimeout(timer);
    }
  }, [show, selfClose, selfCloseDuration, actions]);

  return { show, openModal, closeModal };
};

export default useModal;
